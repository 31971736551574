import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box className="footer">
      <Container className="footer-container">
        <Grid container className="footer-grid">
          <Grid item className="footer-column">
            <Typography variant="h6" gutterBottom>
              STORE.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <strong>Seoul.</strong> 서울특별시 연주로 129길 16
              <br />
              T. 02-512-4416
              <br />
              F. 02-512-4417
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ marginTop: "10px" }}
            >
              <strong>Online.</strong> T. 02-512-4416
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ marginTop: "10px" }}
            >
              <strong>Galleria Dept.</strong> 갤러리아 타임월드 9층 르마블
              <br />
              T. 042 720 6926
            </Typography>
          </Grid>
          <Grid item className="footer-column">
            <Typography variant="h6" gutterBottom>
              BUSINESS HOURS.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <strong>Seoul.</strong>
              <br />
              MON-FRI 09:00 ~ 18:00
              <br />
              SAT 09:00 ~ 18:00 (Break Time - 12:00 ~ 13:00)
            </Typography>
          </Grid>
          <Grid item className="footer-column">
            <Typography variant="h6" gutterBottom>
              CONTACT.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              도매 /프로젝트 문의
              <br />
              <a href="mailto:lemarble@naver.com">lemarble@naver.com</a>
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ marginTop: "10px" }}
            >
              취급점 문의
              <br />
              <a href="mailto:lemarble_sales@naver.com">
                lemarble_sales@naver.com
              </a>
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ marginTop: "10px" }}
            >
              협찬 문의
              <br />
              <a href="mailto:lemarble@daum.net">lemarble@daum.net</a>
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ marginTop: "10px" }}
            >
              For English service, please contact
              <br />
              <a href="mailto:totalmarble.trade@gmail.com">
                totalmarble.trade@gmail.com
              </a>
            </Typography>
          </Grid>
        </Grid>
        <Box className="footer-bottom">
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()} RealEstate Korea. All rights
            reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
