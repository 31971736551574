import React from "react";
import Contact from "./contact";
import ProductHead from "../ProductPage/productHead";
import Footer from "../../components/footer";

export default function ContactPage() {
  return (
    <div>
      <ProductHead />
      <Contact />
      <Footer />
    </div>
  );
}
