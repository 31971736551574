import React from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

export default function Contact() {
  return (
    <div style={{ width: "100%", height: "auto" }}>
      <Container className="contact-container">
        <Typography variant="h1" className="contact-title">
          Contact
        </Typography>
        <Typography variant="body1" className="contact-subtitle">
          with component
        </Typography>
        <Typography variant="body2" className="contact-description">
          문의를 남겨주시면 전문 상담가가 빠르고 친절하게 상담을 진행해
          드립니다.
        </Typography>

        <Box className="attachment-section">
          <Typography variant="body1" className="attachment-title">
            첨부파일
          </Typography>
          <Box className="attachment-box">
            <Typography variant="body2" className="attachment-instruction">
              파일을 선택하거나 여기로 끌어오세요 (JPG, JPEG, PNG, PDF 가능)
            </Typography>
          </Box>
        </Box>

        <Box className="contact-form-section">
          <Typography variant="h6" className="section-title">
            담당자명*
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder="이름을 입력해 주세요."
            className="contact-input"
          />

          <Typography variant="h6" className="section-title">
            연락처*
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder="핸드폰 번호를 입력해 주세요."
            className="contact-input"
          />

          <Typography variant="h6" className="section-title">
            이메일*
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder="이메일 주소를 입력해 주세요."
            className="contact-input"
          />

          <Typography variant="h6" className="section-title">
            소속, 직책
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            placeholder="예) 컴포넌트 team, 프로젝트 매니저"
            className="contact-input"
          />

          <Typography variant="h6" className="section-title">
            문의내용
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            placeholder="내용을 입력해 주세요."
            className="contact-input"
          />

          <FormControlLabel
            control={<Checkbox name="agree" />}
            label="개인정보수집 및 이용에 동의합니다. (자세히 보기)"
            className="contact-checkbox"
          />

          <Button variant="contained" color="primary" className="submit-button">
            문의하기
          </Button>
        </Box>

        <Box className="contact-footer">
          <Typography variant="body2" className="footer-text">
            Copyright © 2024 Component team. All rights reserved. 대한민국
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
