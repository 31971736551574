import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Container,
  Box,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

const fixedData = [
  {
    id: 1,
    title: "공식홈페이지 오픈",
    owner: "온누리공인중개사사무소",
    date: "2024-07-25",
    views: 7,
    detail:
      "안녕하세요! 고객들께 더 나은 서비스를 제공하고자 홈페이지를 오픈하게 되었습니다. 온라인상에서 난립중인 부동산허위,과장매물없는 검증된 100% 실매물로 수시로 변화하는 현장의 흐름을 적시에 반영하여 최상의 거래를 약속드리겠습니다. 많은 이용과 방문 부탁드립니다. 감사합니다.",
  },
];

const ProductBoard = () => {
  const [page, setPage] = useState(1);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const itemsPerPage = 10;

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
    setExpandedRow(null); // Collapse all rows when page changes
  };

  const handleRowClick = (rowId: number) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const displayedData = fixedData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Container className="container">
      <TableContainer component={Paper} className="table-container">
        <Table className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="table-cell-head">번호</TableCell>
              <TableCell className="table-cell-head">제목</TableCell>
              <TableCell className="table-cell-head">이름</TableCell>
              <TableCell className="table-cell-head">날짜</TableCell>
              <TableCell className="table-cell-head">조회수</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((item, index) => (
              <React.Fragment key={item.id}>
                <motion.tr
                  className="table-row"
                  onClick={() => handleRowClick(item.id)}
                  whileHover={{ scale: 1.02, backgroundColor: "#f0f0f0" }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell className="table-cell">
                    {index + 1 + (page - 1) * itemsPerPage}
                  </TableCell>
                  <TableCell className="table-cell">{item.title}</TableCell>
                  <TableCell className="table-cell">{item.owner}</TableCell>
                  <TableCell className="table-cell">
                    {new Date(item.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell className="table-cell">{item.views}</TableCell>
                </motion.tr>
                <AnimatePresence>
                  {expandedRow === item.id && (
                    <TableRow>
                      <TableCell colSpan={5} className="detail-cell">
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <Box p={2}>
                            <strong>상세 정보:</strong>
                            <p>{item.detail}</p>
                          </Box>
                        </motion.div>
                      </TableCell>
                    </TableRow>
                  )}
                </AnimatePresence>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(fixedData.length / itemsPerPage)}
        page={page}
        onChange={handleChangePage}
        color="primary"
        className="pagination"
      />
    </Container>
  );
};

export default ProductBoard;
